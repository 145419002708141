export default {
  api: {
    baseURL: 'https://lk.mcmedic.ru:10011',
    timeout: 10 * 1000,
    code: 'medic_lk_api',
    url: 'https://lk.mcmedic.ru:10011',
    telemedURL: 'https://mcmedic.cloudmedic.ru',
  },
  chat: {
    baseURL: 'https://lk.mcmedic.ru:1338',
  },
  staticURL: 'https://crud.mcmedic.ru/storage/prepared_files/',
}
